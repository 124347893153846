.wave {
  float: left;
  margin: 20px;
  animation: wave ease-in-out 0.5s infinite alternate;
  transform-origin: center -36px;
}

.wave:hover {
  animation-play-state: paused;
  cursor: pointer;
}

.wave img {
  border: 5px solid #f8f8f8;
  display: block;
  width: 200px;
  height: 250px;
}

.wave figcaption {
  text-align: center;
}

.wave:after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1.5px solid #ffffff;
  top: -10px;
  left: 50%;
  border-bottom: none;
  border-right: none;
  transform: rotate(35deg);
}

.wave:before {
  content: '';
  position: absolute;
  top: -23px;
  left: 50%;
  display: block;
  height: 44px;
  width: 47px;
  background-image: url(https://res.cloudinary.com/dmtl2otiy/image/upload/v1708966673/metalTexture_vhedtc.png);
  background-size: 20px 20px;
  background-repeat: no-repeat;
  z-index: 16;
}

@keyframes wave {
  0% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(-10deg);
  }
}